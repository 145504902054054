.footer-container{
    width: 100%;
    height: 697px;
    background: 
        url("../../../assets/fifthpage-images/footer-img/footer-BG.png") no-repeat center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .mobilewebM-footer{
        display: none;
    }
    .webM-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 1040px;
        justify-content: center;
        .calendly{
            display: flex;
            flex-direction: column;
            align-self: center;
            .calendly-inline-widget{
                align-self: center;
                margin: 20px 0;
            }
            h1{
                font-family: "AndersonGroteskBlack";
                text-shadow:
                    -2px -2px #393B3D,
                    0  -2px#393B3D,
                    2px -2px #393B3D,
                    2px  0 #393B3D,
                    2px  2px #393B3D,
                    0  2px #393B3D,
                    -2px  2px #393B3D,
                    -2px  0  #393B3D,
                    -4px  4px  #393B3D;
                color: #F5F2EA;
                letter-spacing: 2px;
                font-size: 28px;
                margin: 10px 0 10px;
            }
            p{
                margin: 10px;
                font-family: "AndersonGroteskBlack";
                color: #393B3D;
            }
        }
        .footer-text{
            width: 510px;
            height: 270px;
        }
        .webM-footer-form{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 1040px;
            .input-fields{
                padding-bottom: 0px;
                border: 2px solid #393B3D;
                box-shadow: -2px 2px 0px #393B3D;
                border-radius: 16px;
                display: flex;
                flex-direction: column;
                background-color: #F5F2EA;
                width: 415px;
                height: 510px;
                justify-content: space-evenly;
                
                .input-title{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-top: 0;
                    align-items: center;
                    h1{
                        width: 335px;
                        font-family: "AndersongroteskBlack";
                        font-size: 28px;
                        margin: 0px;
                        text-shadow:
                            -2px -2px #393B3D,
                            0  -2px#393B3D,
                            2px -2px #393B3D,
                            2px  0 #393B3D,
                            2px  2px #393B3D,
                            0  2px #393B3D,
                            -2px  2px #393B3D,
                            -2px  0  #393B3D,
                            -4px  4px  #393B3D;                  
                        color: #FFCC4D;
                        letter-spacing: 2px;
                        font-family: "AndersonGroteskBlack";
                    }
                    h3{
                        font-size: 18px;
                        font-family: "AndersongroteskUltraBold";
                        width: 195px;
                    }
                }
                .brandInput{
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    align-self: center;
                    margin: 5px 0;
                    width: 320px;
                    label{
                        b{
                            color: #FF6F69;
                        }
                        margin: 0px 0;
                        font-family: "AndersonGrotesk";
                    }
                    input{
                        align-self: center;
                        width: 312px;
                        height: 35px;
                        background: #F5F2EA;
                        border: 2px solid #393B3D;
                        border-radius: 10px;
                        text-indent: 20px;
                    }::placeholder{
                        font-size: 14px;
                    }

                }
                .input-err{
                    align-self: flex-end;
                    font-size: 12px;
                    color: #FF6F69;
                }
                .footerM-btn{ 
                    align-self: center;
                    cursor: pointer;
                    display: flex;
                    padding: 16px 16px 16px 24px;
                    width: 320px;
                    align-items: center;
                    justify-content: space-between;
                    height: 55px;
                    background: #F5F2EA;
                    border: 2px solid #393B3D;
                    border-radius: 10px;
                    margin: 10px 0 0;
                    img{
                        width: 23px;
                        height: 23px;
                    }
                    span{
                        font-family: 'ArchivoBold';
                        font-size: 18px;
                        color: #393B3D;
                    }
                    
                }
                .footerM-btn-disabled{
                    align-self: center;
                    cursor: pointer;
                    display: flex;
                    padding: 16px 16px 16px 24px;
                    width: 320px;
                    align-items: center;
                    justify-content: space-between;
                    height: 55px;
                    background: #F5F2EA;
                    border: 2px solid #6d6e6e;
                    border-radius: 10px;
                    margin: 10px 0 0;
                    img{
                        width: 23px;
                        height: 23px;
                        filter: contrast(0.5);
                    }
                    span{
                        font-family: 'ArchivoBold';
                        font-size: 18px;
                        color: #87898a;
                    }
                }
                .lower-sign{
                    width: 300px;
                    padding: 10px 0px;
                    margin-left: 21px;
                    color: #5D9040;
                    -webkit-text-stroke: 0.4px #393B3D;
                    text-shadow: -1px 1px 0px #393B3D;
                    font-family: "AndersonGrotesk";
                    b{
                        font-family: "AndersonGrotesk";
                        color: #FF6F69;
                        -webkit-text-stroke: 0.4px #393B3D;
                        text-shadow: -1px 1px 0px #393B3D;
                    }
                }
            }   
        }
    }
}


@media(max-width:430px) {
    .footer-container{
        background: 
            url("../../../assets/fifthpage-images/footer-img/foote-bg-mobile.png") no-repeat center;
        background-size: cover;
        width: 100%;
        height: 870px;
        display: flex;
        justify-content: center;
        align-items: center;
        .webM-footer{
            display: none;
        }
        .mobilewebM-footer{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 340px;
            justify-content: space-between;
            .footer-text{
                width: 310px;
                height: 170px;
                margin: 0px 0px 40px;
            }
            .webM-footer-form{
                display: flex;
                flex-direction: column;
                .input-fields{
                    padding-bottom: 0px;
                    border: 2px solid #393B3D;
                    box-shadow: -2px 2px 0px #393B3D;
                    border-radius: 16px;
                    display: flex;
                    flex-direction: column;
                    background-color: #F5F2EA;
                    width: 340px;
                    height: 500px;
                    justify-content: center;
                    .input-title{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-top: 0;
                        align-items: center;
                        h1{
                            font-family: "AndersongroteskBlack";
                            font-size: 26px;
                            margin: 0px;
                            text-shadow:
                                -2px -2px #393B3D,
                                0  -2px#393B3D,
                                2px -2px #393B3D,
                                2px  0 #393B3D,
                                2px  2px #393B3D,
                                0  2px #393B3D,
                                -2px  2px #393B3D,
                                -2px  0  #393B3D,
                                -4px  4px  #393B3D;                  
                            color: #FFCC4D;
                            letter-spacing: 2px;
                            font-family: "AndersonGroteskBlack";

                        }
                        h3{
                            color: #393B3D;
                            margin: 0;
                            align-self: center;
                            font-size: 12px;
                            font-family: "AndersongroteskUltraBold";
                            width: 195px;
                        }
                    }
                    .brandInput{
                        display: flex;
                        flex-direction: column;
                        text-align: left;
                        align-self: center;
                        margin: 5px 0;
                        width: 320px;
                        label{
                            margin: 5px 0;
                            font-size: 12px;
                            font-family: "AndersonGroteskBold";
                        }
                        input{
                            align-self: center;
                            width: 312px;
                            height: 35px;
                            background: #F5F2EA;
                            border: 2px solid #393B3D;
                            border-radius: 10px;
                            text-indent: 20px;
                        }::placeholder{
                            font-size: 12px;
                        }

                    }
                    .footerM-btn{ 
                        align-self: center;
                        cursor: pointer;
                        display: flex;
                        padding: 16px 16px 16px 24px;
                        width: 320px;
                        align-items: center;
                        justify-content: space-between;
                        height: 55px;
                        background: #F5F2EA;
                        border: 2px solid #393B3D;
                        border-radius: 10px;
                        margin: 10px 0 0;
                        img{
                            width: 23px;
                            height: 23px;
                        }
                        span{
                            font-family: 'ArchivoBold';
                            font-size: 18px;
                            color: #393B3D;
                        }
                        
                    }
                    .footerM-btn-disabled{
                        align-self: center;
                        cursor: pointer;
                        display: flex;
                        padding: 16px 16px 16px 24px;
                        width: 320px;
                        align-items: center;
                        justify-content: space-between;
                        height: 55px;
                        background: #F5F2EA;
                        border: 2px solid #6d6e6e;
                        border-radius: 10px;
                        margin: 10px 0 0;
                        img{
                            width: 23px;
                            height: 23px;
                            filter: contrast(0.5);
                        }
                        span{
                            font-family: 'ArchivoBold';
                            font-size: 18px;
                            color: #87898a;
                        }
                    }
                }   
            }
        }
    }
}