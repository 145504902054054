.inMarketPics{
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
    .img-box{
        width: 150px;
        height: 150px;
        .web-in{
            img{
                width: 100px;
                height: 100px;
            }
            h3{
                font-size: 16px;
                font-family: "AndersonGroteskBlack";
                margin: 10 10px;
            } 
        }
        .mobIn{
            display: none;
        }
    }
}

@media(max-width:430px) {
    .inMarketPics{
        display: flex;
        justify-content: space-between;
        margin: 30px 0 0;
        flex-wrap: wrap;
        width: 340px;
        .img-box{
            width: 150px;
            height: 150px;
            .web-in{
                display: flex;
                flex-direction: column;
                align-items: center;
                img{
                    width: 60px;
                    height: 60px;
                }
                h3{
                    color: #393B3D;
                    font-family: "AndersonGroteskBlack";
                    margin: 0;
                    font-size: 14px;
                    letter-spacing: 1px;
                }            
            }
            .mobIn{
                
            }
        }
    } 
}