.main-body{
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: url("../../../assets/images/body-img/Background.png");
    .upper-content{
        margin: 5% 0;
        display: flex;
        align-self: center;
        flex-direction: column;
        justify-content: center;
        font-family: "AndersonGroteskUltraBold";
        h1{
            margin: 20px 0 0 0;
            text-shadow:
                -2px -2px #393B3D,
                0  -2px#393B3D,
                2px -2px #393B3D,
                2px  0 #393B3D,
                2px  2px #393B3D,
                0  2px #393B3D,
                -2px  2px #393B3D,
                -2px  0  #393B3D,
                -4px  4px  #393B3D;
            color: #FFCC4D;
            font-size: 42px;

            letter-spacing: 2px;
            font-family: "AndersonGroteskBlack";
        }
        p{
            font-size: 18px;
            font-family: "AndersonGroteskBlack";
            color: #393B3D;
        }
    }
    /*animations*/
    .reveal{
        position: relative;
        transform: translateY(150px);
        opacity: 0;
        transition: 1s all ease;
    }
      
    .reveal.active{
        transform: translateY(0);
        opacity: 1;
    }

    .active.fade-bottom {
        animation: fade-bottom 1s ease-in;
    }

    .active.fade-left {
        animation: fade-left 1s ease-in;
    }

    .active.fade-right {
        animation: fade-right 1s ease-in;
    }
    @keyframes fade-bottom {
        0% {
          transform: translateY(50px);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }
      @keyframes fade-left {
        0% {
          transform: translateX(-100px);
          opacity: 0;
        }
        100% {
          transform: translateX(0);
          opacity: 1;
        }
      }
      @keyframes fade-right {
        0% {
          transform: translateX(100px);
          opacity: 0;
        }
        100% {
          transform: translateX(0);
          opacity: 1;
        }
    }
    .animation{
        width: 300px;
        align-self: center;
        height: 400px;
    }

    .mob-form-wrapper{
        display: none;
    }

    .popUp-wrapper{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: 1.3;
        display: flex;
        justify-content: center;
        background-color: rgba(0,0,0,0.75);
        z-index: 99;
        .calendly{
            top: 12vh;
            backdrop-filter: blur(15px);
            background-color: rgba(0,0,0,0.75);
            z-index: 9999;
            position: fixed;
            width: 1000px;
            height: 500px;
            align-self: center;
            background: url("../../../assets/images/body-img/popupBG.png")center no-repeat;
            background-size: cover;
            border-radius: 25px;
            border: 2px solid #393B3D;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            .btn-close{
                align-self: flex-end;
                img{
                    width: 30px;
                    height: 30px;
                    margin: 10px 10px 0px 0px;
                    cursor: pointer;
                }
            }
            h1{
                font-family: "AndersonGroteskBlack";
                text-shadow:
                    -2px -2px #393B3D,
                    0  -2px#393B3D,
                    2px -2px #393B3D,
                    2px  0 #393B3D,
                    2px  2px #393B3D,
                    0  2px #393B3D,
                    -2px  2px #393B3D,
                    -2px  0  #393B3D,
                    -4px  4px  #393B3D;
                color: #F5F2EA;
                letter-spacing: 2px;
                font-size: 28px;
                margin: 0px 0 0;
            }
            p{
                margin: 0;
                font-family: "AndersonGroteskBlack";
                color: #393B3D;
            }
        }
        .popUp{
            top: 11vh;
            backdrop-filter: blur(15px);
            background-color: rgba(0,0,0,0.75);
            z-index: 9999;
            position: fixed;
            width: 1000px;
            height: 600px;
            align-self: center;
            background: url("../../../assets/images/body-img/popupBG.png")center no-repeat;
            background-size: cover;
            border-radius: 25px;
            border: 2px solid #393B3D;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            .btn-close{
                width: 40px;
                height: 40px;
                position: relative;
                font-size: 65px;
                font-family: "AndersonGroteskBlack";
                font-weight: 900;
                align-self: end;
                right: 20px;
                top: 20px;
                cursor: pointer;
                img{
                    width: 40px;
                    height: 40px;
                }
            }
            h1{
                font-family: "AndersonGroteskBlack";
                text-shadow:
                    -2px -2px #393B3D,
                    0  -2px#393B3D,
                    2px -2px #393B3D,
                    2px  0 #393B3D,
                    2px  2px #393B3D,
                    0  2px #393B3D,
                    -2px  2px #393B3D,
                    -2px  0  #393B3D,
                    -4px  4px  #393B3D;
                color: #F5F2EA;
                letter-spacing: 2px;
                font-size: 28px;
                margin: 10px 0 0;
            }
            div{
                margin: 10px 0;
                height: 430px;
                display: flex;
                align-items: center;
                .cards-img{
                    width: 385px;
                    height: 400px;
                    margin-right: 40px;
                }
                .input-block{
                    width: 415px;
                    background-color: #F5F2EA;
                    border-radius: 16px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    border: 2px solid #393B3D;
                    box-shadow: -2px 2px 0px #393B3D;
                    span{
                        margin:10px 0 0 35px;
                        font-family: "AndersonGrotesk";
                        align-self: flex-start;
                        b{
                            color: #FF6F69;
                        }
                    }
                    .lower-text{
                        font-family: "AndersonGrotesk";
                        color: #5D9040;
                        text-shadow:
                            -1px -1px #393B3D,
                            0  -1px#393B3D,
                            1px -1px #393B3D,
                            1px  0 #393B3D,
                            1px  1px #393B3D,
                            0  1px #393B3D,
                            -1px  1px #393B3D,
                            -1px  0  #393B3D,
                            -2px  2px  #393B3D;
                    }
                    .input-title{
                        font-family: "AndersonGroteskBlack";
                        text-shadow:
                            -2px -2px #393B3D,
                            0  -2px#393B3D,
                            2px -2px #393B3D,
                            2px  0 #393B3D,
                            2px  2px #393B3D,
                            0  2px #393B3D,
                            -2px  2px #393B3D,
                            -2px  0  #393B3D,
                            -4px  4px  #393B3D;
                        color: #FFCC4D;
                        letter-spacing: 1px;
                        font-size: 22px;
                        width: 355px;
                        margin: 0px 0  20px;
                    }
                    .mailForm{
                        display: flex;
                        height: 250px;
                        position: relative;
                        .mailchimpForm{
                            display: flex;
                            flex-direction: column;
                            height: 250px;
                            .asd{
                                width: 25px;
                                height: 25px;
                                position: absolute;
                                right: 15px;
                                bottom: 38px;
                            }
                            .MailChimp-style{
                               display: flex;
                               flex-direction: column;
                               justify-content: center;
                               .msg-alert{
                                margin: 0px 0 0px;
                                height: 30px;
                                align-self: end;
                                p{
                                    margin: 0px ; 
                                    font-size: 12px;
                                }
                               }
                                input{
                                    text-indent: 20px;
                                    margin: 7px 0;
                                    width: 330px;
                                    height: 35px;
                                    border-radius: 10px;
                                    border: 2px solid #393B3D;
                                    font-family: "AndersonGrotesk";
                                }          
                                button{
                                    cursor: pointer;
                                    display: flex;
                                    padding: 16px;
                                    width: 340px;
                                    align-items: center;
                                    justify-content: space-between;
                                    height: 55px;
                                    background: #F5F2EA;
                                    border: 2px solid #393B3D;
                                    border-radius: 10px;
                                    font-family: 'ArchivoBold';
                                    font-size: 14px;
                                    color: #393B3D;
                                    margin: 7px 0;
                                }
                                button:hover{
                                    box-shadow: -2px 2px 0px #393B3D;
                                    transition: .1s;
                                }  
                            }
                        }
                        
                    }
                    .lower-sign{
                        padding: 10px 0;
                        align-self: flex-start;
                        color: #5D9040;
                        -webkit-text-stroke: 0.4px #393B3D;
                        text-shadow: -1px 1px 0px #393B3D;
                        font-family: "AndersonGrotesk";
                        b{
                            font-family: "AndersonGrotesk";
                            color: #FF6F69;
                            -webkit-text-stroke: 0.4px #393B3D;
                            text-shadow: -1px 1px 0px #393B3D;
                        }
                    }
                }
            }
            .checkpoints{
                display: flex;
                align-self: center;
                justify-content: space-evenly;
                width: 100%;
                margin-bottom: 15px;
                height: 30px;
                div{
                    height: 30px;
                    img{
                        margin: 10px;
                    }
                    label{
                        font-size: 18px;
                        font-family: "AndersonGroteskBlack";
                        color: #393B3D;
                    }
                }
            }
        }
    }
    
    .block-transition{
        display: flex;
        width: 1040px;
        align-items: center;
        align-self: center;
        margin: 40px;
        div{
            margin: 15px;
            margin: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .animate-smile{
                align-self: center;
                position: absolute;
                width: 100px;
                height: 100px;
            }
            .rotating-text{ 
                position: relative;
                align-self: center;
                width: 184px;
                height: 184px;
            }
        }
        hr{
            display: flex;
            flex-direction: column;
            width: 450px;
            height: 0px ;
            color: #393B3D;
            margin: 0px;
        }
    }

    .second-block-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 2% 0;
        h1{
            font-family: "AndersonGroteskBlack";
            font-size: 42px;
            color: #FFCC4D;
            text-shadow:
                -2px -2px #393B3D,
                0  -2px#393B3D,
                2px -2px #393B3D,
                2px  0 #393B3D,
                2px  2px #393B3D,
                0  2px #393B3D,
                -2px  2px #393B3D,
                -2px  0  #393B3D,
                -4px  4px  #393B3D;
            font-weight: bolder;
            letter-spacing: 2px;
        }
        h3{
            margin: 0;
            font-size: 18px;
            font-family: "AndersonGroteskBlack";
        }
        .inner-content{
            align-self: center;
            align-items: center;
            width: 1040px;
            margin: 30px 0;
            .side-img{
                width: 55px;
                height: 125px;
            }
            display: flex;
            justify-content: space-between;
            .inner-card{
                width: 245px;
                height: 240px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                img{
                    align-self: center;
                    width: 90px;
                    height: 84px;
                }
                span{
                    margin-top: 10px;
                    font-family: "AndersonGroteskBlack";
                    font-size: 24px;
                }
                p{
                    font-family: "AndersonGrotesk";
                    font-size: 14px;
                }
            }
        }
    }

    .third-block-content{
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title{
            align-self: center;
            width: 600px;
            align-items: center;
            font-family: "AndersonGroteskBlack";
            color: #FFCC4D;
            text-shadow:
                -2px -2px #393B3D,
                0  -2px#393B3D,
                2px -2px #393B3D,
                2px  0 #393B3D,
                2px  2px #393B3D,
                0  2px #393B3D,
                -2px  2px #393B3D,
                -2px  0  #393B3D,
                -4px  4px  #393B3D;
            font-size: 42px;
            letter-spacing: 2px;
        }
        .inner-content{
            display: flex;
            align-self: center;
            width: 1040px;
            align-items: flex-start;
        }
    }

    .fourth-block-content{
        flex-wrap: wrap;
        margin: 20px 0px 5% 0px ;
        display: flex;
        align-self: center;
        justify-content: flex-start;
        width: 1040px;
        .main-container{
            width: 480px;
            text-align: left;
            margin-top: 20px;
            margin-right: 30px;
            .container-title{
                display: flex;
                img{
                    margin: 0px 10px;
                    width: 50px;
                    height: 50xp;
                }
                span{
                    font-family: "AndersonGroteskBlack";
                    font-size: 42px;
                    text-shadow:
                        -2px -2px #393B3D,
                        0  -2px#393B3D,
                        2px -2px #393B3D,
                        2px  0 #393B3D,
                        2px  2px #393B3D,
                        0  2px #393B3D,
                        -2px  2px #393B3D,
                        -2px  0  #393B3D,
                        -4px  4px  #393B3D;
                        color: #F5F2EA;
                    letter-spacing: 2px;
                }
            }
            p{
                padding: 0 20px 0px 0px;
                font-family: "AndersonGroteskBlack";
            }
        }
    }
}

@media (max-width: 430px) {
    .main-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 430px;
        .upper-content{
            margin: 5% 0;
            width: 340px;
            display: flex;
            align-self: center;
            flex-direction: column;
            justify-content: center;
            h1{
                width: 320px;
                margin: 20px 0 0 0;
                font-size: 26px;
                align-self: center;
            }
            p{
                font-size: 12px;
            }
        }
        .popUp-wrapper{
            display: none;///////////////////////////////////            
            align-items: flex-start;
            
            .calendly{

            }
            .popUp{
                width: 340px;
                align-items: center;
                flex-direction: column;
                justify-content: flex-start;
                h1{
                    font-size: 24px;
                }
                div{
                    width: 300px;
                    display: flex;
                    flex-direction: column;
                    .cards-img{
                        width: 280px;
                        height: 290px;
                    }
                    .input-block{
                        width: 310px;
                        .input-title{
                            width: 300px;
                        }
                        span{
                            display: none;
                        }
                    }
                }
                .checkpoints{
                    display: flex;
                    flex-direction: column;
                }
                .btn-close{
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: 0;
                }
            }
        }
        .mailchimpForm{
            display: none;
        }
    
        .block-transition{
            display: flex;
            width: 340px;
            align-items: center;
            align-self: center;
            margin: 20px 0 20px;
            div{
                margin: 15px;
                width: 160px;
                .animate-smile{
                    width: 80px;
                    height: 80px;
                }
                .rotating-text{
                    width: 145px;
                    height: 145px;
                }
            }
            hr{
                display: flex;
                flex-direction: column;
                width: 450px;
                height: 0px ;
                color: #393B3D;
                margin: 0px;
            }
        }
        .mob-form-wrapper{
            display: flex;
            .popUp-wrapper{
                position: fixed;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                opacity: 1.3;
                display: flex;
                justify-content: center;
                background-color: rgba(0,0,0,0.75);
                z-index: 99;
                .popUp-mob{
                    backdrop-filter: blur(15px);
                    background-color: rgba(0,0,0,0.75);
                    z-index: 9999;
                    position: fixed;
                    height: 580px;
                    max-height: 90vh;
                    width: 345px;
                    align-self: center;
                    background: url("../../../assets/images/body-img/popupBG.png")center no-repeat;
                    background-size: cover;
                    border-radius: 25px;
                    border: 2px solid #393B3D;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    top: 13vh;
                    .btn-close{
                        width: 30px;
                        height: 30px;
                        font-family: "AndersonGroteskBlack";                      
                        cursor: pointer;
                        top: 0;
                        bottom: 0px;
                        align-self: flex-end;
                        margin: -30px 10px 0px 0px;
                        .close-button{               
                            width: 30px;
                            height: 30px;
                            cursor: pointer;                      
                        }
                    }
                    .btn-def{
                        width: 30px;
                        height: 30px;
                        font-family: "AndersonGroteskBlack";                      
                        cursor: pointer;
                        top: 0;
                        left: 0;
                        right: 0;
                        align-self: flex-end;
                        margin: 10px 10px 0px 0px;
                        .close-button{
                            margin: 0px 0px 0px 0px;
                            width: 30px;
                            height: 30px;
                        }
                    }
                    .mailchimp-form-mobile{
                        display: flex;
                        justify-content: center;
                        height: 60vh;
                        .mob-calendly{
                            position: fixed;
                            width: 320px;
                            height: 50vh;
                            align-self: center;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-between;
                            border: none;
                            box-shadow: none;
                            background: none;
                            margin: -190px 0px 0px 0px;
                            .calendly-inline-widget{
                                height: 380px!important;
                                iframe{
                                    height: 380px;    
                                }
                            }
                            h1{
                                font-family: "AndersonGroteskBlack";
                                text-shadow:
                                    -2px -2px #393B3D,
                                    0  -2px#393B3D,
                                    2px -2px #393B3D,
                                    2px  0 #393B3D,
                                    2px  2px #393B3D,
                                    0  2px #393B3D,
                                    -2px  2px #393B3D,
                                    -2px  0  #393B3D,
                                    -4px  4px  #393B3D;
                                color: #F5F2EA;
                                letter-spacing: 2px;
                                font-size: 24px;
                                margin: -10px 0 10px;
                            }
                            p{
                                font-size: 12px;
                                margin: 5px;
                                font-family: "AndersonGroteskBlack";
                                color: #393B3D;
                            }
                        }
                        .input-block{
                            width: 310px;
                            height: 370px;
                            background-color: #F5F2EA;
                            border-radius: 16px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            border: 2px solid #393B3D;
                            box-shadow: -2px 2px 0px #393B3D;
                            .input-title{
                                font-family: "AndersonGroteskBlack";
                                text-shadow:
                                    -2px -2px #393B3D,
                                    0  -2px#393B3D,
                                    2px -2px #393B3D,
                                    2px  0 #393B3D,
                                    2px  2px #393B3D,
                                    0  2px #393B3D,
                                    -2px  2px #393B3D,
                                    -2px  0  #393B3D,
                                    -4px  4px  #393B3D;
                                color: #FFCC4D;
                                letter-spacing: 2px;
                                font-size: 24px;
                                margin: 0px 0 0;
                            }   
                            .input-require{
                                display: flex;
                                margin: 0px 0px 0px 20px;
                            }
                            .mailForm{
                                display: flex;
                                height: 250px;
                                position: relative;
                                .mailchimpForm{
                                    display: flex;
                                    flex-direction: column;
                                    height: 250px;
                                    form{
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        width: 300px;
                                        input{
                                            text-indent: 20px;
                                            margin: 7px 0;
                                            width: 260px;
                                            height: 29px;
                                            border-radius: 10px;
                                            border: 2px solid #393B3D;
                                            font-family: "AndersonGrotesk";
                                        }
                                        button{
                                            width: 270px;
                                            height: 45px;
                                            cursor: pointer;
                                            display: flex;
                                            padding: 16px;
                                            align-items: center;
                                            justify-content: space-between;
                                            background: #F5F2EA;
                                            border: 2px solid #393B3D;
                                            border-radius: 10px;
                                            font-family: 'ArchivoBold';
                                            font-size: 14px;
                                            color: #393B3D;
                                            margin: 7px 0;
                                        }
                                    }
                                    .asd{
                                        align-self: flex-end;
                                        width: 25px;
                                        height: 25px;
                                        position: absolute;
                                        margin: 0;
                                        bottom: 60px;
                                        right: 35px;
                                    }
                                }
                            }
                            span{
                                margin:10px 0 0 35px;
                                font-family: "AndersonGrotesk";
                                align-self: flex-start;
                                b{
                                    color: #FF6F69;
                                }
                            }
                            .lower-text{
                                font-family: "AndersonGrotesk";
                                color: #5D9040;
                                text-shadow:
                                    -1px -1px #393B3D,
                                    0  -1px#393B3D,
                                    1px -1px #393B3D,
                                    1px  0 #393B3D,
                                    1px  1px #393B3D,
                                    0  1px #393B3D,
                                    -1px  1px #393B3D,
                                    -1px  0  #393B3D,
                                    -2px  2px  #393B3D;
                            }
                        }
                    }
                    .pop-present{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        height: auto;
                        .cards-img{
                            width: 270px;
                            height: 290px;
                            margin: 0;
                            position: relative;
                        }
                        .popup-title{
                            font-family: "AndersonGroteskBlack";
                            text-shadow:
                                -2px -2px #393B3D,
                                0  -2px#393B3D,
                                2px -2px #393B3D,
                                2px  0 #393B3D,
                                2px  2px #393B3D,
                                0  2px #393B3D,
                                -2px  2px #393B3D,
                                -2px  0  #393B3D,
                                -4px  4px  #393B3D;
                            color: #F5F2EA;
                            letter-spacing: 2px;
                            font-size: 22px;
                            margin: 10px 0 0;
                        }
                        .popup-btn{
                            margin: 10px 0px;
                            width: 310px;
                            height: 45px;
                            cursor: pointer;
                            display: flex;
                            padding: 16px;
                            align-items: center;
                            justify-content: space-between;
                            height: 45px;
                            background: #F5F2EA;
                            border: 2px solid #393B3D;
                            border-radius: 10px;
                            span{
                                font-family: 'ArchivoBold';
                                font-size: 18px;
                                color: #393B3D;
                            }
                            img{
                                width: 25px;
                                height: 25px;
                            }
                        }
                        .checkpoints-mobile{
                            flex-direction: column;
                            display: flex;
                            width: 310px;
                            height: 90px;
                            margin: 5px 0px 20px 0px;
                            justify-content: space-between;
                            .check-box{
                                align-items: center;
                                height: 15px;
                                margin: 5px 0px 0 0;
                                display: flex;
                                flex-direction: row;
                                img{
                                    margin: 0px 15px 0px 0px;
                                    position: relative;
                                    width: 20px;
                                    height: 20px;
                                }
                                label{
                                    position: relative;
                                    font-size: 12px;
                                    font-family: "AndersonGroteskBlack";
                                    color: #393B3D;
                                }
                            }
                        }
                    }
                }
            }
        }
        .second-block-content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 340px;
            margin: 2% 0 0;
            h1{
                font-size: 26px;
            }
            h3{
                margin: 0;
                font-size: 18px;
            }
            .inner-content{
                display: flex;
                flex-direction: column;
                align-self: center;
                align-items: center;
                width: 340px;
                margin: 0px 0;
                .side-img{
                    display: none;
                }
                display: flex;
                justify-content: space-between;
                .inner-card{
                    width: 275px;
                    height: 180px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin: 25px 0;
                    img{
                        align-self: center;
                        width: 90px;
                        height: 84px;
                    }
                    span{
                        margin-top: 10px;
                        font-size: 18px;
                    }
                    p{
                        font-size: 12px;
                    }
                }
            }
        }

        .third-block-content{
            align-self: center;
            width: 340px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .title{
                width: 300px;
                height: 125px;
                align-self: center;
                font-size: 26px;
            }
            .inner-content{
                display: flex;
                align-self: center;
                width: 340px;
            }
        }
        .fourth-block-content{
            width: 340px;
            margin: 40px 0;
            .main-container{
                margin: 0;
                .container-title{
                    img{
                        width: 40px;
                        height: 40px;
                    }
                    span{
                        font-size: 26px;
                        color: #F5F2EA;
                    }
                }
                p{
                    padding: 0;
                }
            }
        }
    }
}

@media (prefers-reduced-motion: no-preference) {
    .rotating-text{
      animation: rotating-text-spin infinite 20s linear;
    }
  }

@keyframes rotating-text-spin {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

///////////////////

/*
.mailForm{
                        width: 400px;
                        align-self: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        .mailchimpForm{
                            width: 400px;
                            display: flex;
                            justify-content: center;
                            .asd{
                               
                            }
                            .MailChimp-style{
                                align-self: center;
                                display: flex;
                                align-items: center;
                                flex-direction: column;
                                width: 400px;
                                height: 300px;
                                input{
                                    text-indent: 20px;
                                    margin: 7px 0;
                                    width: 325px;
                                    height: 35px;
                                    border-radius: 10px;
                                    border: 2px solid #393B3D;
                                }          
                                button{
                                    cursor: pointer;
                                    display: flex;
                                    padding: 16px;
                                    width: 340px;
                                    align-items: center;
                                    justify-content: space-between;
                                    height: 55px;
                                    box-shadow: -2px 2px 0px #393B3D;
                                    background: #F5F2EA;
                                    border: 2px solid #393B3D;
                                    border-radius: 10px;
                                    font-family: 'ArchivoBold';
                                    font-size: 14px;
                                    color: #393B3D;
                                    margin: 7px 0;
                                }  
                            }
                        }
                        img{
                            position: absolute;
                            width: 25px;
                            height: 25px;
                        }
                    }*/