.webApp_footer-container{
    width: 100%;
    height: 697px;
    background: 
        url("../../../assets/sixthpage-images/footer-img/webapp_footer-BG.png") no-repeat center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .mobileDev-footer{
        display: none;
    }
    .webApp-footer{
        display: flex;
        align-items: center;
        width: 1040px;
        justify-content: space-between;
        flex-direction: column;
        .footer-text{
            width: 530px;
            height: 480px;
        }
        .calendly{
            display: flex;
            flex-direction: column;
            align-self: center;
            .calendly-inline-widget{
                align-self: center;
                margin: 20px 0;
            }
            h1{
                font-family: "AndersonGroteskBlack";
                text-shadow:
                    -2px -2px #393B3D,
                    0  -2px#393B3D,
                    2px -2px #393B3D,
                    2px  0 #393B3D,
                    2px  2px #393B3D,
                    0  2px #393B3D,
                    -2px  2px #393B3D,
                    -2px  0  #393B3D,
                    -4px  4px  #393B3D;
                color: #F5F2EA;
                letter-spacing: 2px;
                font-size: 28px;
                margin: 10px 0 10px;
            }
            p{
                margin: 10px;
                font-family: "AndersonGroteskBlack";
                color: #393B3D;
            }
        }
        .webM-footer-form{
            display: flex;
            width: 1040px;
            justify-content: space-between;
            align-items: center;
            .input-fields{
                padding-bottom: 0px;
                border: 2px solid #393B3D;
                box-shadow: -2px 2px 0px #393B3D;
                border-radius: 16px;
                display: flex;
                flex-direction: column;
                background-color: #F5F2EA;
                width: 400px;
                height: 500px;
                justify-content: center;
                span{
                    color: #FF6F69;
                    align-self:flex-end ;
                    font-size: 12px;
                    font-family: "AndersonGrotesk";
                }
                .input-title{
                    display: none;
                    flex-direction: column;
                    justify-content: center;
                    margin-top: 0;
                    align-items: center;
                    h1{
                        font-family: "AndersongroteskBlack";
                        font-size: 43px;
                        margin: 0px;
                        text-shadow: -2px 2px 1px #393B3D;
                        color: #FFCC4D;
                        -webkit-text-stroke: 1px black;
                        font-size: 43px;
                        letter-spacing: 10%;
                        font-family: "AndersonGroteskBlack";

                    }
                    h3{
                        font-size: 18px;
                        font-family: "AndersongroteskUltraBold";
                        width: 195px;
                    }
                }
                .webAppInput{
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    align-self: center;
                    margin: 5px 0;
                    width: 320px;
                    label{
                        margin: 10px 0;
                        font-family: "AndersonGroteskBold";
                    }
                    input{
                        align-self: center;
                        width: 312px;
                        height: 35px;
                        background: #F5F2EA;
                        border: 2px solid #393B3D;
                        border-radius: 10px;
                        text-indent: 20px;
                    }::placeholder{
                        font-size: 14px;
                    }

                }
                .footerM-btn{ 
                    align-self: center;
                    cursor: pointer;
                    display: flex;
                    padding: 16px 16px 16px 24px;
                    width: 320px;
                    align-items: center;
                    justify-content: space-between;
                    height: 55px;
                    background: #F5F2EA;
                    border: 2px solid #393B3D;
                    border-radius: 10px;
                    margin: 10px 0 0;
                    img{
                        width: 23px;
                        height: 23px;
                    }
                    span{
                        font-family: 'ArchivoBold';
                        font-size: 18px;
                        color: #393B3D;
                    }
                    
                }
                .footerM-btn-disabled{
                    align-self: center;
                    cursor: pointer;
                    display: flex;
                    padding: 16px 16px 16px 24px;
                    width: 320px;
                    align-items: center;
                    justify-content: space-between;
                    height: 55px;
                    background: #F5F2EA;
                    border: 2px solid #6d6e6e;
                    border-radius: 10px;
                    margin: 10px 0 0;
                    img{
                        width: 23px;
                        height: 23px;
                        filter: contrast(0.5);
                    }
                    span{
                        font-family: 'ArchivoBold';
                        font-size: 18px;
                        color: #87898a;
                    }
                }
            }   
        }
    }
}

@media(max-width:430px) {
    .webApp_footer-container{
        width: 100%;
        height: 740px;
        display: flex;
        justify-content: center;
        align-items: center;
        .mobileDev-footer{
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 340px;
            justify-content: space-between;
            .footer-text{
                width: 340px;
                height: 290px;
                position: relative;
            }
            .webM-footer-form{
                display: flex;
                flex-direction: column;
                margin: -55px 0px 0px;
                .input-fields{
                    padding-bottom: 0px;
                border: 2px solid #393B3D;
                box-shadow: -2px 2px 0px #393B3D;
                border-radius: 16px;
                display: flex;
                flex-direction: column;
                background-color: #F5F2EA;
                width: 340px;
                height: 400px;
                justify-content: center;
                .input-title{
                    display: none;
                    flex-direction: column;
                    justify-content: center;
                    margin-top: 0;
                    align-items: center;
                    h1{
                        font-family: "AndersongroteskBlack";
                        font-size: 26px;
                        margin: 0px;
                        text-shadow: -2px 2px 1px #393B3D;
                        color: #FFCC4D;
                        -webkit-text-stroke: 1px black;
                        font-size: 43px;
                        letter-spacing: 10%;
                        font-family: "AndersonGroteskBlack";

                    }
                    h3{
                        font-size: 18px;
                        font-family: "AndersongroteskUltraBold";
                        width: 195px;
                    }
                }
                .webAppInput{
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    align-self: center;
                    margin: 5px 0;
                    width: 320px;
                    label{
                        margin: 10px 0;
                        font-size: 12px;
                        font-family: "AndersonGroteskBold";
                    }
                    input{
                        align-self: center;
                        width: 312px;
                        height: 29px;
                        background: #F5F2EA;
                        border: 2px solid #393B3D;
                        border-radius: 10px;
                        text-indent: 20px;
                    }::placeholder{
                        font-size: 12px;
                    }

                }
                .footerM-btn{ 
                    align-self: center;
                    cursor: pointer;
                    display: flex;
                    padding: 16px 16px 16px 24px;
                    width: 320px;
                    align-items: center;
                    justify-content: space-between;
                    height: 45px;
                    background: #F5F2EA;
                    border: 2px solid #393B3D;
                    border-radius: 10px;
                    margin: 10px 0 0;
                    img{
                        width: 23px;
                        height: 23px;
                    }
                    span{
                        font-family: 'ArchivoBold';
                        font-size: 18px;
                        color: #393B3D;
                    }
                    
                }
                .footerM-btn-disabled{
                    align-self: center;
                    cursor: pointer;
                    display: flex;
                    padding: 16px 16px 16px 24px;
                    width: 320px;
                    align-items: center;
                    justify-content: space-between;
                    height: 45px;
                    background: #F5F2EA;
                    border: 2px solid #6d6e6e;
                    border-radius: 10px;
                    margin: 10px 0 0;
                    img{
                        width: 23px;
                        height: 23px;
                        filter: contrast(0.5);
                    }
                    span{
                        font-family: 'ArchivoBold';
                        font-size: 18px;
                        color: #87898a;
                    }
                }
                }   
            }
        }
        .webApp-footer{
            display: none;
        }
    }
}