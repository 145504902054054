.privacy{
    background: url("../../assets/images/footer-img/lowerfooter-bg.png")no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    width: 100%;
    align-self: center;
    display: flex;
    height: 220px;
    justify-content: space-around;
    align-items: center;
    .inner-container{
        width: 1040px;
        display: flex;
        justify-content: space-between;
        .image-block{
            display: flex;
            align-items: center;
            img{
                background: transparent;
                width: 152px;
                height: 52px;
            }
        }
        .text-container{
            display: flex;
            align-items: center;
            .inner-content{
                display: flex;
                flex-direction: column;
                span{
                    width: 250px;
                    font-family: "AndersonGrotesk";
                    color: #F5F2EA;
                    text-align: left;
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .privacy{
        -webkit-background-size: cover;
        background-size: cover;
        width: 100%;
        align-self: center;
        display: flex;
        height: 280px;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .inner-container{
            width: 340px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin: 0 5px;
            .image-block{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                img{
                    background: transparent;
                    width: 98px;
                    height: 32px;
                }
                p{
                    font-family: "ArchivoBlack";
                    color: #F5F2EA;
                    width: 90px;
                    font-size: 12px;
                    text-align: left;
                    width: 80px;
                    margin-left: 10px;
                }
            }
            .text-container{
                display: flex;
                align-items: center;
                flex-direction: column;
                .inner-content{
                    display: flex;
                    flex-direction: column;
                    margin: 5px 5px;
                    span{
                        width: 240px;
                        font-family: "AndersonGrotesk";
                        color: #F5F2EA;
                        text-align: left;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}