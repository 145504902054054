.benefits-box{
    display: flex;
    flex-wrap: wrap;
    margin: 2% 0;
    width: 1040px;
    .benefits-block{
        width: 500px;
        height: 125px;
        text-align: left;
        margin-right: 20px;
        .web-benefits{
            .benefits-title{
                display: flex;
                align-items: center;
                img{
                    width: 40px;
                    height: 40px;
                    margin: 0px 10px 10px 0px;
                }
                h3{
                    margin: 20px 0;
                    font-family: "AndersonGroteskBlack";
                    font-size: 28px;
                    text-shadow:
                        -2px -2px #393B3D,
                        0  -2px#393B3D,
                        2px -2px #393B3D,
                        2px  0 #393B3D,
                        2px  2px #393B3D,
                        0  2px #393B3D,
                        -2px  2px #393B3D,
                        -2px  0  #393B3D,
                        -4px  4px  #393B3D;                  
                    color:  #F5F2EA;
                    letter-spacing: 2px;
                }
            }
            p{
                font-size: 14px;
                margin: 0;
                font-family: "AndersonGroteskBlack";
                color: #393B3D;
            }
        }
    }
}

@media(max-width:430px) {
    .benefits-box{
        display: flex;
        flex-wrap: wrap;
        margin: 2% 0;
        width: 340px;
        .benefits-block{
            width: 340px;
            height: 125px;
            text-align: left;
            margin: 10px 0;
            .web-benefits{
                display: flex;
                flex-direction: column;
                margin: 20px 0;
                .benefits-title{
                    display: flex;
                    align-items: center;
                    img{
                        width: 40px;
                        height: 40px;
                        margin: 0px 10px 10px 0px;
                    }
                    h3{
                        margin: 20px 0;
                        font-family: "AndersonGroteskBlack";
                        font-size: 22px;
                        text-shadow:
                            -2px -2px #393B3D,
                            0  -2px#393B3D,
                            2px -2px #393B3D,
                            2px  0 #393B3D,
                            2px  2px #393B3D,
                            0  2px #393B3D,
                            -2px  2px #393B3D,
                            -2px  0  #393B3D,
                            -4px  4px  #393B3D;                  
                        color:  #F5F2EA;
                        letter-spacing: 2px;
                    }
                }
                p{
                    font-size: 12px;
                    margin: 0;
                    font-family: "AndersonGroteskBlack";
                    color: #393B3D;
                }            }
            .mob-benefits{
                
            }
        }
    }
    
}