    .header-container{
        align-self: center;
        background: url("../../../assets/images/header-img/BG.jpg") center no-repeat; 
        background-size: cover;       
        height: 782px;
        display: flex;
        flex-direction: column;
        width: 100%;
        header{
            display: flex;
            justify-content: space-evenly;
            margin: 20px;
            top: 0;
            z-index: 200;
            .header-logo{
                display: flex;
                font-family: "ArchivoBlack";
                font-size: 14px;
                align-items: center;
                p{
                    width: 97px;
                    margin: 0px 0px 0px 10px ;
                    color: #F5F2EA;
                    text-align: left;
                }
            }
            .header-panel{
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                
                a{
                    width: 150px;
                    height: 30px;
                    font-family: "ArchivoMedium" ;
                    font-weight: 500;
                    border-radius: 5px;
                    cursor: pointer;
                    margin: 0px 10px 0px 10px;
                    font-family: "ArchivoBold";
                    font-size: 13px;
                    color: #F5F2EA;
                }
            }
            
        }
    
        .header-text{
            width: 100%;
            display: flex;
            justify-content: center;
            .main-text{
                display: flex;
                flex-direction: column;
                div{
                    img{
                        width: 1000px;
                        height: 270px;
                        z-index: 0;
                        position: relative;
                    } 
                }
                .mobile-header{
                    display: none;
                }
            }
            p{
                align-self: center;
                font-family: "AndersonGroteskBlack";
                font-size: 18px;
                color: #F5F2EA;
                width: 750px;
                margin: 30px;
            }
            .header-btn{
                button{
                    cursor: pointer;
                    display: flex;
                    padding: 16px;
                    width: 190px;
                    align-items: center;
                    justify-content: space-between;
                    height: 55px;
                    background: #F5F2EA;
                    border: 2px solid #393B3D;
                    border-radius: 10px;
                    
                    img{
                        width: 23px;
                        height: 23px;
                    }
                    span{
                        font-family: 'ArchivoBold';
                        font-size: 18px;
                        color: #393B3D;
                    }
                }
                button:hover{
                    box-shadow: -2px 2px 0px #393B3D;
                    transform: scale(1.1);
                    transition: .25s;
                }
            }
            
            .form-wrapper{
                align-self: center;
                position: fixed;
                align-items: center;
                top: 0;
                right: 0;
                left: 0;
                bottom: -100vh;
                opacity: 1.3;
                background-color: rgba(0,0,0,0.75);
                z-index: 9999;
                .brand-footer-form{
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    align-self: center;
                    z-index: 9999;
                    margin-top: 3%;
                    .succsess{
                        margin: 10% 0;
                        width: 400px;
                        height: 280px;
                        align-self: center;
                        background-color: #F5F2EA;
                        text-align: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        border-radius: 16px;
                        box-shadow: -2px 2px 0px #393B3D;
                        justify-content: center;
                        h1{
                            font-family: "AndersongroteskBlack";
                            font-size: 42px;
                            margin: 0px 0px 10px ;
                            text-shadow:
                                -2px -2px #393B3D,
                                0  -2px#393B3D,
                                2px -2px #393B3D,
                                2px  0 #393B3D,
                                2px  2px #393B3D,
                                0  2px #393B3D,
                                -2px  2px #393B3D,
                                -2px  0  #393B3D,
                                -4px  4px  #393B3D;                          
                            color: #FFCC4D;
                            font-size: 28px;
                            letter-spacing: 2px;
                        }
                       .suptitle-succsess{
                            color: #393B3D;
                            font-size: 18px;
                            width: 300px;
                        }
                        button{
                            cursor: pointer;
                            margin: 20px 0 0;
                            border-radius: 10px;
                            width: 70px;
                            height: 55px;
                            background: #5D9040;
                            border: 2px solid #393B3D;
                            box-shadow: -2px 2px 0px #393B3D;
                            span{
                                font-family: "ArchivoBold";
                                color: #F5F2EA;
                                font-size: 18px;
                            }
                        }
                    }
                    .input-fields{
                        z-index: 9999;
                        padding-bottom: 0px;
                        border: 2px solid #393B3D;
                        box-shadow: -2px 2px 0px #393B3D;
                        border-radius: 16px;
                        display: flex;
                        flex-direction: column;
                        background-color: #F5F2EA;
                        width: 400px;
                        height: 550px;
                        justify-content: center;
                        margin: 5px;
                        .close-btn{
                            margin-right: 20px;
                            width: 35px;
                            height: 35px;
                            align-self: flex-end;
                            cursor: pointer;
                        }
                        .input-title{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            margin-top: 0;
                            align-items: center;
                            h1{
                                font-family: "AndersongroteskBlack";
                                font-size: 42px;
                                margin: 0px 0px 10px ;
                                text-shadow:
                                    -2px -2px #393B3D,
                                    0  -2px#393B3D,
                                    2px -2px #393B3D,
                                    2px  0 #393B3D,
                                    2px  2px #393B3D,
                                    0  2px #393B3D,
                                    -2px  2px #393B3D,
                                    -2px  0  #393B3D,
                                    -4px  4px  #393B3D;                          
                                color: #FFCC4D;
                                font-size: 28px;
                                letter-spacing: 2px;
                            }
                            h3{
                            font-size: 14px;
                            color: #393B3D;
                            font-family: "AndersongroteskBlack";
                            width: 195px;
                            margin: 0px 0px 10px;
                            letter-spacing: 1px;
                           }
                        }
                        .brandInput{
                            display: flex;
                            flex-direction: column;
                            text-align: left;
                            align-self: center;
                            margin: 5px 0;
                            width: 320px;
                            label{
                                margin: 5px 0 0;
                                font-family: "AndersonGroteskBold";
                            }
                            input{
                                align-self: center;
                                width: 312px;
                                height: 35px;
                                background: #F5F2EA;
                                border: 2px solid #393B3D;
                                border-radius: 10px;
                                text-indent: 20px;
                            }::placeholder{
                                font-size: 14px;
                            }
                            span{
                                align-self: flex-end;
                                font-size: 12px;
                                color: #FF6F69;
                                font-family: "AndersonGrotesk";
                            }
        
                        }
                        .footerM-btn{ 
                            align-self: center;
                            cursor: pointer;
                            display: flex;
                            padding: 16px 16px 16px 24px;
                            width: 320px;
                            align-items: center;
                            justify-content: space-between;
                            height: 55px;
                            background: #F5F2EA;
                            border: 2px solid #393B3D;
                            border-radius: 10px;
                            margin: 10px 0 0;
                            img{
                                width: 23px;
                                height: 23px;
                            }
                            span{
                                font-family: 'ArchivoBold';
                                font-size: 18px;
                                color: #393B3D;
                            }
                            
                        }
                        .footerM-btn-disabled{
                            align-self: center;
                            cursor: pointer;
                            display: flex;
                            padding: 16px 16px 16px 24px;
                            width: 320px;
                            align-items: center;
                            justify-content: space-between;
                            height: 55px;
                            background: #F5F2EA;
                            border: 2px solid #6d6e6e;
                            border-radius: 10px;
                            margin: 10px 0 0;
                            img{
                                width: 23px;
                                height: 23px;
                                filter: contrast(0.5);
                            }
                            span{
                                font-family: 'ArchivoBold';
                                font-size: 18px;
                                color: #87898a;
                            }
                        }
                    }   
                }
            }
        }
    }

@media(max-width: 430px){
    .header-container{
        height: 670px;
        background: url("../../../assets/images/header-img/mobileBG.png") center no-repeat; 
        background-size: cover;
        width: 100%;
        max-width: 430px;
        display: flex;
        justify-content: center;
        header{
            align-items: flex-start;
            justify-content: left;
            .header-logo{
                
            }
            .header-panel{
                display: none;
            }
        }
        .header-text{
            width: 340px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0px 0 20px;
            align-self: center;
            .main-text{
                width: 340px;
                .mobile-header{
                    display: flex;
                    height: 180px;
                    margin: 30px 0;
                }
                img{
                    display: none;
                }
            }
            p{
                margin: 0px 0px;
                width: 340px;
                font-size: 12px;
                font-family: "AndersonGroteskUltraBold";
            }
            .header-btn{
                margin-top: 15px;
                button{
                    cursor: pointer;
                    display: flex;
                    padding: 10px;
                    width: 150px;
                    align-items: center;
                    justify-content: space-between;
                    height: 45px;
                    background: #F5F2EA;
                    border: 2px solid #393B3D;
                    border-radius: 10px;
                    box-shadow: -2px 2px 0px #393B3D;
                    img{
                        width: 23px;
                        height: 23px;
                    }
                    span{
                        font-family: 'ArchivoBold';
                        font-size: 14px;
                    }
                }
            }
            .form-wrapper{
                display: flex;
                align-self: center;
                justify-content: center;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                .brand-footer-form{
                    margin: 25% 0;
                    height: 480px;
                    display: flex;
                    flex-direction: column;
                    .input-fields{
                        align-self: center;
                        width: 340px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .close-btn{
                            margin-bottom: 10px;
                        }
                        .input-title{
                            h3{
                                display: none;
                            }
                        }
                        .brandInput{
                            width: 300px;
                            label{
                                align-self: flex-start;
                                font-size: 12px;
                            }
                            input{
                                width: 290px;
                                height: 29px;
                            }
                        }
                        .footer-btn{
                            align-self: center;

                            width:300px;
                            .footerM-btn{
                                width: 300px;
                                height: 44px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media(min-width:2560px){
    .header-container{
        width: 100%;
    }   
}
@media(min-width:3840px){
    .header-container{
        width: 100%;
        max-width: 3840px;
    }
}

@media(min-width:375px){
    .header-container{
        align-self: center;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        .header-text{
            display: flex;
            justify-content: center;
        }
    }
}