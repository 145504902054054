.disciplines-container{
    display: flex;
    width: 1040px;
    flex-wrap: wrap;
    .discipline-block{
        .web-dis{
            display: flex;
            align-items: center;
            text-align: left;
            width: 240px;
            height: 40px;
            margin: 20px 20px 20px 0px;
            img{
                width: 40px;
                height: 40px;
            }
            h3{
                font-size: 18px;
                margin-left: 20px;
                width: 170px;
                font-family: "AndersonGroteskBlack";
                color: #393b3d;
                letter-spacing: 1px;
            }
        }
        .mob-dis{
            display: none;
        }
    }
}
@media(max-width:430px) {
    .disciplines-container{
        display: flex;
        width: 340px;
        flex-wrap: wrap;
        margin: 30px 0;
        justify-content: space-between;
        .discipline-block{
            display: flex;
            align-items: center;
            text-align: left;
            width: 160px;
            height: 35px;
            margin: 15px 0;
            .mob-dis{
                
            }
            .web-dis{
                display: flex;
                align-items: center;
                img{
                    width: 40px;
                    height: 40px;
                    margin-right: 10px;
                }
                h3{
                    font-size: 14px;
                    margin: 0px;
                    width: 120px;
                    font-family: "AndersonGroteskBlack";
                    color: #393b3d;
                    letter-spacing: 1px;
                }            }
        }
    }
}