.market-footer{
    background: url("../../../assets/thirdpage-images/footer-img/marketingFooter-BG.png") no-repeat center;
    background-size: cover;
    width: 100%;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 610px;
    .footer-box{
        display: flex;
        width: 1040px;
        align-items: center;
        justify-content: center;
        .poster{
            margin-left: 20px;
            width: 440px;
            height: 400px;
        }
        .marketPoster-mobile{
            display: none;
        }
        .content{
            display: flex;
            flex-direction: column;
            width: 510px;
            height: 285px;
            text-align: left;
            margin: 4% 0% 3% 3%;
            justify-content: space-between;
            .inner-text{
                display: flex;
                flex-direction: column;
                margin: 5% 0 0;
                h1{
                    font-family: "AndersonGroteskBlack";
                    font-size: 42px;
                    letter-spacing: 1px;
                    text-shadow: -2px -2px 0px #393B3D, -4px 4px 0px #393B3D, 2px -2px 0px #393B3D, 2px 2px 0px #393B3D;
                    color: #F5F2EA;
                    margin: 0;
                }
                p{
                    font-family: "AndersonGroteskBlack";
                    color: #F5F2EA;
                    font-size: 18px;
                }
            }
            .footerM-btn{
                cursor: pointer;
                display: flex;
                padding: 16px 16px 16px 24px;
                width: 510px;
                align-items: center;
                justify-content: space-between;
                height: 55px;
                background: #F5F2EA;
                border: 2px solid #393B3D;
                border-radius: 10px;
                box-shadow: -2px 2px 0px #393B3D;
                margin: 7px 0;
                img{
                    width: 23px;
                    height: 23px;
                }
                span{
                    font-family: 'ArchivoBold';
                    font-size: 18px;
                    color: #393B3D;
                }
                
            }
            .form-wrapper{
                align-self: center;
                position: fixed;
                align-items: center;
                top: 0;
                right: 0;
                left: 0;
                bottom: -100vh;
                opacity: 1.3;
                background-color: rgba(0,0,0,0.75);
                z-index: 9999;
                .brand-footer-form{
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    align-self: center;
                    z-index: 9999;
                    margin-top: 3%;
                    .succsess{
                        margin: 10% 0;
                        width: 400px;
                        height: 280px;
                        align-self: center;
                        background-color: #F5F2EA;
                        text-align: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        border-radius: 16px;
                        box-shadow: -2px 2px 0px #393B3D;
                        justify-content: center;
                        h1{
                            font-family: "AndersongroteskBlack";
                            font-size: 42px;
                            margin: 0px 0px 10px ;
                            text-shadow:
                                -2px -2px #393B3D,
                                0  -2px#393B3D,
                                2px -2px #393B3D,
                                2px  0 #393B3D,
                                2px  2px #393B3D,
                                0  2px #393B3D,
                                -2px  2px #393B3D,
                                -2px  0  #393B3D,
                                -4px  4px  #393B3D;                          
                            color: #FFCC4D;
                            font-size: 28px;
                            letter-spacing: 2px;
                        }
                       .suptitle-succsess{
                            color: #393B3D;
                            font-size: 18px;
                            width: 300px;
                            font-family: "AndersongroteskBlack";
                        }
                        
                        button{
                            cursor: pointer;
                            margin: 20px 0 0;
                            border-radius: 10px;
                            width: 70px;
                            height: 55px;
                            background: #5D9040;
                            border: 2px solid #393B3D;
                            box-shadow: -2px 2px 0px #393B3D;
                            span{
                                font-family: "ArchivoBold";
                                color: #F5F2EA;
                                font-size: 18px;
                            }
                        }
                    }
                    .input-fields{
                        z-index: 9999;
                        padding-bottom: 0px;
                        border: 2px solid #393B3D;
                        box-shadow: -2px 2px 0px #393B3D;
                        border-radius: 16px;
                        display: flex;
                        flex-direction: column;
                        background-color: #F5F2EA;
                        width: 400px;
                        height: 550px;
                        justify-content: center;
                        margin: 5px;
                        .close-btn{
                            margin-right: 20px;
                            width: 35px;
                            height: 35px;
                            align-self: flex-end;
                            cursor: pointer;
                        }
                        .input-title{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            margin-top: 0;
                            align-items: center;
                            h1{
                                font-family: "AndersongroteskBlack";
                                font-size: 42px;
                                margin: 0px 0px 10px ;
                                text-shadow:
                                    -2px -2px #393B3D,
                                    0  -2px#393B3D,
                                    2px -2px #393B3D,
                                    2px  0 #393B3D,
                                    2px  2px #393B3D,
                                    0  2px #393B3D,
                                    -2px  2px #393B3D,
                                    -2px  0  #393B3D,
                                    -4px  4px  #393B3D;                          
                                color: #FFCC4D;
                                font-size: 28px;
                                letter-spacing: 2px;
                            }
                            h3{
                            font-size: 14px;
                            color: #393B3D;
                            font-family: "AndersongroteskBlack";
                            width: 195px;
                            margin: 0px 0px 10px;
                            letter-spacing: 1px;
                           }
                        }
                        .brandInput{
                            display: flex;
                            flex-direction: column;
                            text-align: left;
                            align-self: center;
                            margin: 5px 0;
                            width: 320px;
                            label{
                                margin: 5px 0 0;
                                font-family: "AndersonGroteskBold";
                            }
                            input{
                                align-self: center;
                                width: 312px;
                                height: 35px;
                                background: #F5F2EA;
                                border: 2px solid #393B3D;
                                border-radius: 10px;
                                text-indent: 20px;
                            }::placeholder{
                                font-size: 14px;
                            }
                            span{
                                align-self: flex-end;
                                font-size: 12px;
                                color: #FF6F69;
                                font-family: "AndersonGrotesk";
                            }
        
                        }
                        .footerM-btn{ 
                            align-self: center;
                            cursor: pointer;
                            display: flex;
                            padding: 16px 16px 16px 24px;
                            width: 320px;
                            align-items: center;
                            justify-content: space-between;
                            height: 55px;
                            background: #F5F2EA;
                            border: 2px solid #393B3D;
                            border-radius: 10px;
                            margin: 10px 0 0;
                            img{
                                width: 23px;
                                height: 23px;
                            }
                            span{
                                font-family: 'ArchivoBold';
                                font-size: 18px;
                                color: #393B3D;
                            }
                        }
                        .footerM-btn-disabled{
                            align-self: center;
                            cursor: pointer;
                            display: flex;
                            padding: 16px 16px 16px 24px;
                            width: 320px;
                            align-items: center;
                            justify-content: space-between;
                            height: 55px;
                            background: #F5F2EA;
                            border: 2px solid #6d6e6e;
                            border-radius: 10px;
                            margin: 10px 0 0;
                            img{
                                width: 23px;
                                height: 23px;
                                filter: contrast(0.5);
                            }
                            span{
                                font-family: 'ArchivoBold';
                                font-size: 18px;
                                color: #87898a;
                            }
                        }
                    }   
                }
            }
           
            .input-container{
                display: flex;
                flex-direction: column;     
                height: 50px;
                margin-bottom: 30px;
                input{
                    text-indent: 20px;
                    justify-content: flex-end;
                    width: 515px;
                    height: 50px;
                    border: 2px solid #393B3D;
                    background:  #F5F2EA;
                    box-shadow: -2px 2px 0px #393B3D;
                    border-radius: 10px;
                    position: absolute;
                    display: flex;
                }::placeholder{
                    font-family: "AndersonGroteskUltraBold";
                    font-size: 18px;
                }

                button{
                    margin: 12px 15px 0px 0px;
                    display: flex;
                    align-self: end;
                    width: 25px;
                    height: 25px;
                    cursor: pointer;
                    img{
                        position: absolute;
                        margin-bottom: 5px;
                        width: 23px;
                        height: 23px;
                    }
                }
            }
        }
    }
}

@media (max-width:430px) {
    .market-footer{
        background: url("../../../assets/thirdpage-images/footer-img/mobileBGmarket.png") no-repeat ;
        background-size: cover;
        width: 100%;
        align-self: center;
        justify-content: space-around;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 610px;
        .footer-box{
            display: flex;
            width: 340px;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            .poster{
                display: none;
            }
            .marketPoster-mobile{
                display: flex;
                width: 275px;
                height: 245px;
                margin: 10px 0;
            }
            .content{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 10px 0;
                width: 340px;
                height: auto;
                .inner-text{
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    margin: 0% 0 0;
                    h1{
                        font-size: 26px;
                        margin: 0;
                    }
                    p{
                        align-self: center;
                        width: 290px;
                        color: #F5F2EA;
                        font-size: 12px;
                    }
                }
                .footerM-btn{
                    cursor: pointer;
                    display: flex;
                    padding: 16px 16px 16px 24px;
                    width: 340px;
                    align-items: center;
                    justify-content: space-between;
                    height: 55px;
                    background: #F5F2EA;
                    border: 2px solid #393B3D;
                    border-radius: 10px;
                    box-shadow: -2px 2px 0px #393B3D;
                    margin: 7px 0;
                    img{
                        width: 23px;
                        height: 23px;
                    }
                    span{
                        font-family: 'ArchivoBold';
                        font-size: 18px;
                        color: #393B3D;
                    }
                    
                }
                .form-wrapper{
                    display: flex;
                    align-self: center;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    justify-content: center;
                    .brand-footer-form{
                        margin: 25% 0;
                        height: 480px;
                        .input-fields{
                            width: 340px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            .close-btn{
                                margin-bottom: 10px;
                            }
                            .input-title{
                                h3{
                                    display: none;
                                }
                            }
                            .brandInput{
                                width: 300px;
                                label{
                                    align-self: flex-start;
                                    font-size: 12px;
                                }
                                input{
                                    width: 290px;
                                    height: 29px;
                                }
                            }
                            
                            .footerM-btn{
                                width: 300px;
                                height: 44px;
                            }
                            .footerM-btn-disabled{
                                width: 300px;
                                height: 44px;
                            }
                            
                        }
                    }
                }
            }
        }
    }
}