.expertise-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .container{
        width: 505px;
        .animated-web{
            width: 505px;
                .title{
                    display: flex;
                    align-items: center;
                    text-align: left;
                    margin: 20px 0px 20px;
                    img{
                        width: 40px;
                        height: 40px;
                        margin: 0px 20px 0px 0px;
                    }
                    span{
                        width: 450px;
                        font-size: 28px;
                        font-family: "AndersonGroteskBlack";
                        text-shadow:
                            -2px -2px #393B3D,
                            0  -2px#393B3D,
                            2px -2px #393B3D,
                            2px  0 #393B3D,
                            2px  2px #393B3D,
                            0  2px #393B3D,
                            -2px  2px #393B3D,
                            -2px  0  #393B3D,
                            -4px  4px  #393B3D;                  
                        color: #F5F2EA;
                        letter-spacing: 2px;
                    }
                }
            p{
                text-align: left;
                font-size: 14px;
                color: #393B3D;
                font-family: "AndersonGroteskBlack";
            }
            
            
        }
        .mob-expertise{
            display: none;
        }
        
    }
}

@media(max-width:430px) {
    .expertise-container{
        .container{
            width: 340px;
            margin: 10px 0;
            .animated-web{
                width: 340px;
                display: flex;
                flex-direction: column;
                .title{
                    display: flex;
                    align-items: center;
                    img{
                        width: 40px;
                        height: 40px;
                    }
                    span{
                        text-align: left;
                        font-size: 22px;
                        font-family: "AndersonGroteskBlack";
                        text-shadow:
                            -2px -2px #393B3D,
                            0  -2px#393B3D,
                            2px -2px #393B3D,
                            2px  0 #393B3D,
                            2px  2px #393B3D,
                            0  2px #393B3D,
                            -2px  2px #393B3D,
                            -2px  0  #393B3D,
                            -4px  4px  #393B3D;                  
                        color: #F5F2EA;
                        letter-spacing: 2px;
                    }
                }
                p{
                    font-size: 12px;
                    font-family: "AndersonGroteskBlack";
                    text-align: left;
                    color: #393B3D;
                    margin: 0;
                }            
            }
            .mob-expertise{
                
            }
        }
    }
}