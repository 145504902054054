.smm-pic{
    display: flex;
    width: 400px;
    height: 630px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .social-box{
        display: flex;
        margin: 10px;
        align-items: center;
        width: 145px;
        .web-smm{
            display: flex;
        margin: 10px;
        align-items: center;
        width: 145px;
            img{
                width: 50px;
                height: 50px;
            }
            h3{
                font-size: 18px;
                margin-left: 10px;
                font-family: "AndersonGroteskBlack";
                color: #393b3d;
                letter-spacing: 0.5px;
            }
        }
        .mob-social{
            display: none;
        }   
    }
}

@media(max-width:430px) {
    .smm-pic{
        display: flex;
        width: 340px;
        height: 330px;
        flex-wrap: wrap;
        align-items: baseline;
        justify-content: space-between;
        margin: 20px 0 0;
        .social-box{
            display: flex;
            align-items: center;
            width: 145px;
            margin: 0;
            .web-smm{
                display: flex;
                align-items: center;
                img{
                    width: 30px;
                    height: 30px;
                }
                h3{
                    font-size: 14px;
                    margin-left: 10px;
                    font-family: "AndersonGroteskBlack";
                    color: #393b3d;
                    letter-spacing: 0.5px;
                }
            }
            .mob-social{
                
            }
        }
    }
}